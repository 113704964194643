import { red } from '@material-ui/core/colors'
import { frFR } from '@material-ui/core/locale'
import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme(
  {
    typography: {
      fontFamily: `"Jost", sans-serif`,
      h1: {
        fontSize: 30,
        marginBottom: 20
      },
      subtitle1: {
        fontSize: '22px',
        lineHeight: '24px',
        color: '#F6B02A',
        textTransform: 'uppercase'
      },
      subtitle2: {
        fontSize: '21px',
        lineHeight: '21px',
        color: '#FFF',
        textTransform: 'uppercase',
        backgroundColor: '#F6B02A',
        display: 'inline-flex',
        padding: 12,
        paddingBottom: 8,
        marginBottom: '21px'
      }
    },
    palette: {
      type: 'dark',
      primary: {
        main: '#F6B02A',
        dark: '#d49a2a'
      },
      // secondary: {
      //   main: '#19857b'
      // },
      error: {
        main: red.A400,
        dark: red.A700
      },
      background: {
        default: '#000'
      }
    },
    overrides: {
      MuiAppBar: {
        root: {
          backgroundColor: 'black',
          height: 150,
          [`@media print`]: {
            height: 45
          }
        }
      },
      MuiInputBase: {
        input: {
          borderColor: 'white',
          borderWidth: 2,
          borderStyle: 'solid',
          padding: 10
        },
        inputMultiline: {
          padding: 10
        },
        multiline: {
          padding: 0
        }
      },
      MuiTextField: {
        root: {
          marginBottom: 20
        }
      },
      MuiInputLabel: {
        root: {
          color: '#FFF',
          textTransform: 'uppercase',
          fontSize: '28px',
          lineHeight: '50px',
          whiteSpace: 'nowrap'
        },
        formControl: {
          position: 'relative'
        }
      },
      MuiButton: {
        containedPrimary: {
          color: '#000',
          backgroundColor: '#F6B02A',
          ['&:hover']: {
            backgroundColor: '#d49a2a'
          }
        },
        outlined: {
          borderColor: 'white',
          borderWidth: 1
        }
      }
    }
  },
  frFR
)

export default theme
