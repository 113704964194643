// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-deals-index-tsx": () => import("./../../../src/pages/deals/index.tsx" /* webpackChunkName: "component---src-pages-deals-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-merchant-index-tsx": () => import("./../../../src/pages/merchant/index.tsx" /* webpackChunkName: "component---src-pages-merchant-index-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-sales-management-index-tsx": () => import("./../../../src/pages/salesManagement/index.tsx" /* webpackChunkName: "component---src-pages-sales-management-index-tsx" */),
  "component---src-pages-statement-index-tsx": () => import("./../../../src/pages/statement/index.tsx" /* webpackChunkName: "component---src-pages-statement-index-tsx" */)
}

